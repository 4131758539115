import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
};

const showOrderModalSlice = createSlice({
  name: "isOpen",
  initialState,
  reducers: {
    setIsOpen: (state, action) => {
      state.isOpen = action.payload;
    },
  },
});

export const getIsOpen = (state: any) => {
  return state.isOpen.isOpen;
};

export const { setIsOpen } = showOrderModalSlice.actions;

export default showOrderModalSlice.reducer;
