import axios, { AxiosInstance, AxiosResponse, AxiosError } from 'axios'

const getToken = () => {
  const userDataString = localStorage.getItem('userData')
  
  if (userDataString) {
    try {
      const userData = JSON.parse(userDataString)

      if (userData && userData.token) {

        return userData.token
      }
    } catch (error) {
      console.error('Error parsing userData from localStorage', error)
    }
  }
  
  return ''
}


const api: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-BSTORE-ID': process.env.REACT_APP_STORE_ID,
  },
})

api.interceptors.request.use(
  config => {
    const token = getToken()
    
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

api.interceptors.response.use(
  (response: AxiosResponse) => (response?.data ? response?.data : response),
  (error: AxiosError) => {
    return Promise.reject(error)
  }
)

export default api
